import React, { useRef } from "react"

// import { isEmpty } from "lodash"

import { isIOS } from "react-device-detect"
import FORM_CONFIG, { SUCCESS_MSG_DOWNLOAD } from "./config"
import Form from "./elements/Form"
// import LanguageSelect from "./elements/LanguageSelect"
import PhoneInput from "./elements/PhoneInput"
import Recaptcha from "./elements/Recaptcha"
import TermsConditions from "./elements/TermsConditions"
// import TextAreaInput from "./elements/TextAreaInput"
import TextInput from "./elements/TextInput"
import { downloadReportSchema } from "./schemas"
import {
  formTracking,
  generateUuid,
  getBitrixData,
  isProduction,
  postFormData,
  sendMail,
  useBlock,
} from "./utils"
import useEmailContent from "@hooks/dataHooks/useEmailContent"
import { useGlobalStore } from "@stores/globalStore"
const DownloadForm = ({ email, name, submitText, file, noScrollOnSuccess }) => {
  const recaptchaRef = useRef()
  const data = useEmailContent()
  const { ipAddress } = useGlobalStore()
  const formConfig = FORM_CONFIG.download
  let emailContentTemp = data?.filter((item) => item.choose_template ===  formConfig?.emailTempUser);
  const emailContent = emailContentTemp[0]?.content?.data?.content;
  const onSubmit = async (data) => {
    const ipList = await useBlock();
    const isBlocked = ipList?.ip?.some(item => item?.add_ip_address === ipAddress);
    const isBlocked1 = ipList?.email?.some(item => item?.email === data.email);
    const token = await recaptchaRef.current?.executeAsync()
      // if (file) {
      //   setTimeout(() => {
      //     // window.open(file)
      //     const anchor = document.createElement("a")
      //     document.body.appendChild(anchor)
      //     anchor.style = "display: none"
      //     anchor.href = file
      //     anchor.download = "report.pdf"
      //     if (!isIOS) {
      //       anchor.target = "_blank"
      //     }
      //     anchor.click()
      //     document.body.removeChild(anchor)
      //   }, 2000)
      // }
     
    // navigate(file?.url, "_blank")
    if (file) {
      setTimeout(() => {
        // window.open(file)
        const anchor = document.createElement("a")
        document.body.appendChild(anchor)
        anchor.style = "display: none"
        anchor.href = file
        anchor.download = "report.pdf"
        if (!isIOS) {
          anchor.target = "_blank"
        }
        anchor.click()
        document.body.removeChild(anchor)
      }, 2000)
    }
   
    if (token) {
      const unique_id = generateUuid()
      const postData = {
        first_name: data.name?.split(" ")[0],
        contactName: name,
        contactEmail: email,
        unique_id: unique_id,
        "g-recaptcha-response": token,
        ...data,
        ...formConfig,
        extra: { formConfig, data },
      }
      const bitrixData = getBitrixData(postData)

      const formData = new FormData()

      formData.append("data", JSON.stringify(bitrixData))

      recaptchaRef.current?.reset()
      if(isBlocked || isBlocked1){
        return;
      }
      else {
      postFormData(formData).then(async () => {
        await sendMail(bitrixData)
      })
      formTracking({
        unique_id: unique_id,
        eventTracking: formConfig.eventTracking,
        formName: formConfig.form_name,
      })
    }
  }
  }

  return (
    <Form
      className="team-contact-form"
      validationSchema={downloadReportSchema}
      onSubmit={onSubmit}
      successMsg={SUCCESS_MSG_DOWNLOAD}
      noScrollOnSuccess={noScrollOnSuccess}
    >
      <div className="form-grid">
        <TextInput name="name" placeholder="Name" label="Name" />
        <TextInput
          name="email"
          placeholder="Email"
          type="email"
          label="Email"
        />
        <PhoneInput name="telephone" label="Phone" />
        {/* <LanguageSelect /> */}
        {/* <TextAreaInput
          name="message"
          placeholder="Share any additional information"
          label="Message"
        /> */}
        <div className="d-none"><TextInput name="emailContent" type="hidden" value={emailContent} /></div>
      </div>
      <div className="form-bottom">
        <button className="button button-orange" type="submit">
          <span>{submitText || "Submit Details"}</span>
        </button>
        <TermsConditions />
      </div>
      <Recaptcha recaptchaRef={recaptchaRef} />
    </Form>
  )
}

export default DownloadForm
